import { Box, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { FinndoffUser } from "../../utils/contexts/UserContext";
import { useSnackbar } from "../../utils/hooks/useSnackbar";
import { useApi } from "../../utils/hooks/useApi";
import { useNavigate, useLocation } from "react-router-dom";
import meflogo from "../../images/MEF_logo.svg";
import ablogo from "../../images/ab_logo.png";
import anbud365logo from "../../images/anbud365.png";
import elektroinstLogo from "../../images/elektroinst_logo.png";
import finndoffLogo from "../../images/varslingtjeneste_folk.png";
import byggeborsenfauske2024Logo from "../../images/byggeborsenfauske2024_logo.png";
import byggmesterforbundetLogo from "../../images/byggmesterforbundet_logo.png";
import nesoLogo from "../../images/neso_logo.jpg";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";

interface Address {
  adresse: string[];
  postnummer: string;
  poststed: string;
}

interface Naeringskode {
  kode: string;
  beskrivelse: string;
}

interface OrgDetails {
  organisasjonsnummer: string;
  navn: string;
  forretningsadresse: Address;
  postadresse?: Address;
  naeringskode1?: Naeringskode;
}

interface SignupFormProps {
  emailSent: (isSent: boolean) => void;
  inviteError: (hasInviteError: boolean) => void;
  searchesFormTrigger: (setSearchesForm: boolean) => void;
  externalUserId: (setExternalUserId: string) => void;
  industryCode: (setindustryCode: string) => void;
}

const ORG_NUM_LENGTH = 9;

export const SignupFormBransje: FunctionComponent<SignupFormProps> = ({ emailSent, inviteError, searchesFormTrigger, externalUserId, industryCode}) => {
  const navigate = useNavigate();

  const { enqueueErrorMsg } = useSnackbar();
  const [refOrg, setRefOrg] = useState("");

  const [orgNum, setOrgNum] = useState(refOrg ? refOrg : "");
  const [orgNumErrorMsg, setOrgNumErrorMsg] = useState("");
  const [isCorrectOrgNum, setIsCorrectOrgNum] = useState(false);
  const [websiteURL, setWebsiteURL] = useState("");
  const [websiteURLErrorMsg, setWebsiteURLErrorMsg] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [naeringskode, setCompanyNaeringskode] = useState("");
  const [naeringskodeBeskrivelse, setCompanyNaeringskodeBeskrivelse] = useState("");
  const [billingAddressLine1, setBillingAddressLine1] = useState("");
  const [billingAddressLine2, setBillingAddressLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalPlace, setPostalPlace] = useState("");
  const [phone, setPhone] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [newsletterChosen, setNewsletterChosen] = useState(true);

  const [isInviteLoading, setIsInviteLoading] = useState(false);

  const params = new URLSearchParams(useLocation().search);
  const referralCode = params.get("_referral");

  const onChangeOrgNum = async (orgIn: string) => {
    const onWrongOrgNum = (_orgNum: string, _status: string) => {
      // Avoid showing error message before the user has finished typing
      if (_orgNum.length !== ORG_NUM_LENGTH) {
        return;
      }

      if (orgNum.length !== ORG_NUM_LENGTH) {
        setIsCorrectOrgNum(false);

        setOrgNumErrorMsg("Ikke gyldig orgnummer.");
      }

      removeWrongParams();
      setOrgNumErrorMsg("Fant ikke organisasjonsnummeret i Br.reg.");
      resetOrgFields();
    };

    const removeWrongParams = () => {
      if (params.has("_org")) {
        params.delete("_org");
        navigate(params.toString()); // TODO ???
        setOrgNum("");
      }
    };

    const resetOrgFields = () => {
      setCompanyName("");
      setWebsiteURL("");
      setBillingAddressLine1("");
      setBillingAddressLine2("");
      setPostalCode("");
      setPostalPlace("");
    };

    const orgNumFromInputField = orgIn.replace(/\s/g, "");
    setOrgNum(orgNumFromInputField);

    if (orgNumFromInputField.length === 9) {
      setOrgNum(orgNumFromInputField);
      const resp = await fetch("https://data.brreg.no/enhetsregisteret/api/enheter/" + orgNumFromInputField);
      if (!resp.ok) {
        onWrongOrgNum(orgNumFromInputField, resp.statusText);
        setIsCorrectOrgNum(false);
        setRefOrg("");
        return;
      }
      const json = await resp.json();
      if (typeof json === "object" && json.hasOwnProperty("organisasjonsnummer")) {
        setIsCorrectOrgNum(true);
        setOrgDetails(json);
      }
    } else {
      setIsCorrectOrgNum(false);
      setOrgNumErrorMsg("Ikke gyldig orgnummer.");
      resetOrgFields();
    }
  };

  useEffect(() => {
    if (params.has("_org")) {
      onChangeOrgNum(params.get("_org"));
    }
  }, [params]);

  // Regular expression for validating a basic URL structure
  const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;

  // const handleWebsiteURLChange = (value: string) => {
  //   setWebsiteURL(value);
  //   if (urlPattern.test(value) || value === "") {
  //     setWebsiteURLErrorMsg("");
  //   } else {
  //     setWebsiteURLErrorMsg("Ugyldig adresse for hjemmeside.");
  //   }
  // };

  const toSignupApiBody = (): NewFinndoffUser => ({
    orgNummer: orgNum,
    hjemmeside: websiteURL,
    firmaNavn: companyName,
    industryCode: naeringskode,
    industryCodeDesc: naeringskodeBeskrivelse,
    adresselinje1: billingAddressLine1,
    adresselinje2: billingAddressLine2,
    postNummer: postalCode,
    postSted: postalPlace,
    kontaktperson: contact,
    kontaktpersonMobil: phone,
    epostadresse: email,
    nyhetsbrev: newsletterChosen,
    passord: "",
    isPersonalSubscription: false,
    referenceOrganization: referralCode ? referralCode : "",
    productId : 1 //smart, until we have selection for this
  });

  const { saveUserMethod: apiSaveUser } = SaveUserApiMethods(toSignupApiBody());
  const { industrySignupMethod: apiIndustrySignupUser } = IndustrySignupApiMethods(toSignupApiBody());

  // TODO const cancelRegistration = () => navigate("/portal");

  const setOrgDetails = (json: OrgDetails) => {
    setOrgNumErrorMsg("");
    setOrgNum(json.organisasjonsnummer);
    setCompanyName(json.navn);
    setCompanyNaeringskode(json.naeringskode1?.kode);
    industryCode(json.naeringskode1?.kode);
    setCompanyNaeringskodeBeskrivelse(json.naeringskode1?.beskrivelse);
    let address: Address;
    if (typeof json.postadresse === "object") {
      address = json.postadresse;
    } else {
      address = json.forretningsadresse;
    }
    const addressLines = address.adresse;
    if (addressLines.length > 0) {
      setBillingAddressLine1(addressLines.shift() || "");
    }
    if (addressLines.length > 0) {
      setBillingAddressLine2(addressLines.join(", "));
    }
    //backup in case there is no address in bronnoysund
    if(billingAddressLine1.length === 0)
    {
      setBillingAddressLine1(address.postnummer + " " + address.poststed);
    }

    setPostalCode(address.postnummer);
    setPostalPlace(address.poststed);
  };

  const printSearch = () => {
    if (refOrg === "") return;

    onChangeOrgNum(refOrg);
  };

  const canSave =
    orgNumErrorMsg === "" &&
    billingAddressLine1 !== "" &&
    postalCode !== "" &&
    postalPlace !== "" &&
    phone !== "";

  const saveInvite = async () => {
    try {
      setIsInviteLoading(true);
      if(referralCode != "" && referralCode != null)
      {
          await apiSaveUser().then((results) => {
            if (results?.externalUserId) {
              setIsInviteLoading(false);
              externalUserId(results.externalUserId);
              emailSent(true);
            } else if (results?.externalUserId == null) {
              inviteError(true);
              enqueueErrorMsg("Feil på lagring av bruker");
            }
          });
        }
        else
        {
          await apiIndustrySignupUser().then((results) => {
            if (results?.externalUserId) {
              setIsInviteLoading(false);
              externalUserId(results.externalUserId);
              searchesFormTrigger(true);
              emailSent(true);
            } else if (results?.externalUserId == null) {
              inviteError(true);
              enqueueErrorMsg("Feil på lagring av bruker");
            }
          });
        }

      // setTimeout(() => history.push(paths.portal), 300);
    } catch (e) {
      setIsInviteLoading(false);
      enqueueErrorMsg(`Kunne ikke lagre bruker: ${e.toString()}`);
    }
  };

  printSearch();
  return (
    <>
      {!isInviteLoading ? (
        <Grid
          sx={{
            padding: (theme) => theme.spacing(3),
            "& .MuiFormControl-root": {
              margin: (theme) => theme.spacing(1),
            },
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              marginBottom: "5%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {(() => {
              switch (referralCode) {
                case "mefmember":
                  return <Box component="img" src={meflogo} alt=""></Box>;
                // eventuelt legge til flere 'case' her, om nødvendig
                case "mefsorost":
                  return <Box component="img" src={meflogo} alt=""></Box>;
                case "mefvest":
                  return <Box component="img" src={meflogo} alt=""></Box>;
                case "mefmidt":
                  return <Box component="img" src={meflogo} alt=""></Box>;
                case "mefsorvest":
                  return <Box component="img" src={meflogo} alt=""></Box>;
                case "mefost":
                  return <Box component="img" src={meflogo} alt=""></Box>;
                case "clickedtitle":
                  return <Box component="img" width="100%" src={finndoffLogo} alt=""></Box>;
                case "anbud365":
                  return <Box component="img" src={anbud365logo} alt=""></Box>;
                case "arkitektbedriftene":
                  return <Box component="img" src={ablogo} alt=""></Box>;
                case "elektroinst":
                  return <Box component="img" src={elektroinstLogo} alt=""></Box>;
                case "byggeborsenfauske2024":
                  return <Box component="img" src={byggeborsenfauske2024Logo} alt=""></Box>;
                case "byggmesterforbundet":
                  return <Box component="img" src={byggmesterforbundetLogo} alt=""></Box>;
                case "neso":
                  return <Box component="img" src={nesoLogo} alt=""></Box>;
                default:
                  return null;
              }
            })()}
          </Box>
          <TextField
            label="Org nummer (9 sifre)"
            value={orgNum}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 11 }}
            onChange={(e) => onChangeOrgNum(e.target.value)}
            fullWidth
            required
            error={!!orgNumErrorMsg}
            helperText={orgNumErrorMsg}
          />
          <Box width="90%">
            {isCorrectOrgNum && <TextField label="Firmanavn" value={companyName} disabled fullWidth />}
            {/* <TextField label="Adresse" value={billingAddressLine1} disabled fullWidth />
            <TextField value={billingAddressLine2} disabled fullWidth />
            <Grid container>
            <Grid item xs={4}>
            <TextField label="Postnr" value={postalCode} disabled fullWidth />
            </Grid>
            <Grid item xs={8}>
            <TextField label="Poststed" value={postalPlace} disabled fullWidth />
            </Grid>
          </Grid> */}
          </Box>
          {/* <TextField
            label="Hjemmeside"
            value={websiteURL}
            onChange={(e) => handleWebsiteURLChange(e.target.value)}
            fullWidth
            error={!!websiteURLErrorMsg}
            helperText={websiteURLErrorMsg}
          /> */}
          <TextField
            label="Kontaktperson"
            value={contact}
            autoComplete="name"
            inputProps={{ autoComplete: "name" }}
            onChange={(e) => setContact(e.target.value)}
            required
            fullWidth
          />
          <TextField
            label="Epost"
            value={email}
            autoComplete="email"
            inputProps={{ autoComplete: "email" }}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
          />
          <TextField
            label="Telefon"
            autoComplete="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            fullWidth
          />
          <FormControlLabel
            label="Jeg ønsker nyhetsbrev fra Finndoff"
            control={
              <Checkbox
                color="primary"
                checked={newsletterChosen}
                onChange={() => setNewsletterChosen(!newsletterChosen)}
              />
            }
          />
          <Grid
            item
            xs={12}
            paddingTop="10%"
            textAlign="center"
            sx={{
              "& > *": {
                margin: (theme) => theme.spacing(1),
              },
            }}
          >
            {/* <Button variant="contained" color="secondary" onClick={cancelRegistration}>
              Fotsett uten registrering
            </Button> */}

            <PrimaryButton disabled={!canSave} onClick={saveInvite} text={"Registrer bruker!"} />
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ paddingTop: "60%" }}>
          <LoadingIndicator />
        </Box>
      )}
    </>
  );
};

export type NewFinndoffUser = {
  orgNummer?: string;
  hjemmeside?: string;
  firmaNavn: string;
  adresselinje1?: string;
  adresselinje2?: string;
  postNummer?: string;
  postSted?: string;
  kontaktperson: string;
  kontaktpersonMobil: string;
  epostadresse: string;
  nyhetsbrev: boolean;
  passord: string;
  isPersonalSubscription: boolean;
  referenceOrganization: string;
  industryCode: string;
  industryCodeDesc: string;
  productId: number;
};

export type UserSearches = {
  ExternalUserId: string;
  Industries : string[];
};

export const SaveUserApiMethods = (user: NewFinndoffUser) => {
  const { error, loading, post } = useApi("/api/me/bruker/signup");
  return {
    error,
    loading,
    saveUserMethod: async (): Promise<FinndoffUser> => {
      const body = user;
      const apiResponse = await post("", body);
      return apiResponse;
    },
  };
};

export const IndustrySignupApiMethods = (user: NewFinndoffUser) => {
  const { error, loading, post } = useApi("/api/me/bruker/industrysignup");
  return {
    error,
    loading,
    industrySignupMethod: async (): Promise<FinndoffUser> => {
      const body = user;
      const apiResponse = await post("", body);
      return apiResponse;
    },
  };
};


