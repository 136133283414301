import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { config } from '../../config';

const RegisterContactMePage: React.FC = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        if (!token) {
            setError('Mangler token.');
            setLoading(false);
            return;
        }

        const registerContact = async () => {
            try {
                const response = await fetch(
                    `${config.backendUrl}/api/me/bruker/contactme?token=${encodeURIComponent(token)}`,
                    { method: 'GET' }
                );

                if (!response.ok) {
                    throw new Error('Noe gikk galt under registreringen.');
                }

                const result = await response.json();

                if (result === true) {
                    setSuccess(true);
                } else {
                    setError('Kunne ikke registrere deg. Vennligst prøv igjen.');
                }
            } catch (err) {
                console.error('Feil ved registrering:', err);
                setError('Noe gikk galt, vennligst kontakt oss her.');
            } finally {
                setLoading(false);
            }
        };

        registerContact();
    }, [location.search]);

    return (
        <Container>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                paddingTop="20px"
                paddingBottom="20px"
            >
                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <>
                        <Typography variant="h6" color="error" align="center">
                            {error}
                        </Typography>
                        <Link
                            href="https://share-eu1.hsforms.com/183V6W1IGRcikwgsn9G8Nngfai8j"
                            target="_blank"
                            rel="noreferrer"
                            sx={{
                                fontSize: '1.2rem',
                                fontWeight: 500,
                                color: '#008489',
                                marginTop: '10px',
                                textAlign: 'center',
                            }}
                        >
                            Noe gikk galt kontakt oss her
                        </Link>
                    </>
                ) : success ? (
                    <Typography variant="h6" color="primary" align="center">
                        Vi vil kontakte deg snart! 
                    </Typography>
                ) : null}
            </Box>
        </Container>
    );
};

export default RegisterContactMePage;
