import React, { useState } from "react";
import { useSnackbar } from "../../../../utils/hooks/useSnackbar";
import { useModal } from "../../../../components/Modal/Modal";
import { Box, Grid, TextField, Typography, useMediaQuery, useTheme, Checkbox, FormControlLabel } from "@mui/material";
import { createSearch, isNew, Search } from "../../../../Types/Search";
import { useReferenceDataContext } from "../../../../utils/contexts/ReferenceDataContext";
import { getNodeById } from "../../../../utils/tree";
import { paths } from "../../../../app/paths";
import { useUserContext } from "../../../../utils/contexts/UserContext";
import { PrimaryButton } from "../../../../components/Buttons/PrimaryButton";
import { SecondaryButton } from "../../../../components/Buttons/SecondaryButton";
import { LightButton } from "../../../../components/Buttons/LightButton";
import { useNavigate } from "react-router-dom";
import { StyledChip } from "../../../../components/StyledChip";

interface SaveButtonAndModalProps {
  disabled?: boolean;
  saveSearch: (search: Search) => Promise<Search>;
  currentSearch: Search;
  initialSearchName: string;
}

export const SaveButtonAndModal: React.FC<SaveButtonAndModalProps> = ({
  disabled,
  saveSearch,
  currentSearch,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { enqueueSuccessMsg, enqueueErrorMsg } = useSnackbar();
  const { steder, kategorier } = useReferenceDataContext();
  const { finndoffUser } = useUserContext();
  const [currentSearchName, setCurrentSearchName] = useState(currentSearch.navn);

  const [textOnlyAlerts, setTextOnlyAlerts] = useState(currentSearch.textOnlyAlerts || false); // State for textOnlyAlerts

  const emails = currentSearch.mottakere && currentSearch.mottakere.length > 0 
  ? currentSearch.mottakere 
  : [finndoffUser.epostadresse];


  const { openModal: openSaveModal, closeModal: closeSaveModal, Modal: SaveModal } = useModal("saveModal");
  const doSaveSearch = (search: Search): void => {
    const emailString = emails.join(";"); // Convert the emails array to a semicolon-separated string
    saveSearch({ ...search, navn: currentSearchName, mottakere: emails, textOnlyAlerts }) // Include emailString for mottakere
      .then((savedSearch) => {
        closeSaveModal();
        enqueueSuccessMsg("Finndoffvarsling aktivert og lagt til mine søk!", {
          onExited: () => {
            if (isNew(search))
              setImmediate(() =>
                navigate(paths.searchEdit.replace(":id", savedSearch.eksternId), { replace: true })
              );
          },
        });
      })
      .catch(() => {
        enqueueErrorMsg("Lagring feilet");
      });
  };

  const doSaveSearchAsCurrent = () => {
    doSaveSearch(currentSearch);
  };

  const doSaveSearchAsNew = () => {
    const newSearch = createSearch(currentSearch);
    doSaveSearch(newSearch);
  };

  const stedText =
    Array.from(currentSearch.stedIds)
      .map((stedId) => getNodeById(steder, stedId)?.label)
      .join(", ") || "Alle";

  const kategoriTekst =
    Array.from(currentSearch.kategoriIds)
      .map((kategoriId) => getNodeById(kategorier, kategoriId).label)
      .join(", ") || "Alle";

  function setValue(value: string) {
    if (value.length > 100) return currentSearch.navn;

    setCurrentSearchName(value);
  }

  const canSave = (): boolean => {
    return !!currentSearch.navn && !!emails.length;
  };

  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <>
      <SaveModal title="Aktiver Finndoffvarsling!" width={xs ? "600px" : "400px"}>
        <>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="Søktittel"
              margin="dense"
              fullWidth
              value={currentSearchName}
              onChange={(e) => setValue(e.target.value)}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textSecondary">
              Søkekriterier
            </Typography>
            <Box padding={1}>
              <Typography variant="body2">Fritekst: {currentSearch.searchText}</Typography>
              <Typography variant="body2">Sted: {stedText}</Typography>
              <Typography variant="body2">CPV-nummer: {kategoriTekst}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textSecondary">
              Mottaker
            </Typography>
            {emails.map((email, i) => {
              return (
                <Grid item key={email}>
                  <StyledChip key={i} label={email} />
                </Grid>
              );
            })}
          </Grid>
          
          {/* Checkbox for textOnlyAlerts */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={textOnlyAlerts}
                  onChange={(e) => setTextOnlyAlerts(e.target.checked)}
                  color="primary"
                />
              }
              label="Motta kun tekstvarsler"
            />
          </Grid>

          <Grid item xs={12}>
            <Box style={{ marginTop: "1em" }}>
              <Grid container direction="row" spacing={2} justifyContent="center">
                <Grid item>
                  <PrimaryButton disabled={!canSave()} onClick={doSaveSearchAsCurrent} text={"Lagre"} />
                </Grid>
                {!isNew(currentSearch) && (
                  <Grid item>
                    <PrimaryButton disabled={!canSave()} onClick={doSaveSearchAsNew} text={"Lagre som ny"} />
                  </Grid>
                )}
                <Grid item>
                  <LightButton onClick={() => closeSaveModal()} text={"Lukk"} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </>
      </SaveModal>
      <SecondaryButton
        disabled={disabled}
        onClick={openSaveModal}
        text={isNew(currentSearch) ? "Lagre søk" : "Endre søk"}
      />
    </>
  );
};
