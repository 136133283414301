import React from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { AnbudSummary } from "./datatypes";
import { formatISODate } from "../../../utils/formatISODate";
import { nodeIdOrParentChecked, someChildrenCheckedById } from "../../../utils/tree";
import { useReferenceDataContext } from "../../../utils/contexts/ReferenceDataContext";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "../../../app/paths";
import { Link as RouterLink } from 'react-router-dom';
import { useUserContext } from "../../../utils/contexts/UserContext";

interface SearchResultProps {
  summary: AnbudSummary;
  checkedStedIds: Set<number>;
  checkedKategoriIds: Set<number>;
  checkedKategoriCPV: Set<number>;
  setCheckedKategori: (id: number, checked: boolean) => void;
  setCheckedSted: (id: number, checked: boolean) => void;
}

export const SearchResult: React.FC<SearchResultProps> = ({
  summary,
  checkedStedIds,
  checkedKategoriIds,
  checkedKategoriCPV,
  setCheckedKategori,
  setCheckedSted,
}) => {
  const { steder, kategorier } = useReferenceDataContext();
  const { isAuthenticated } = useAuth0();
  const { hasProcurementPlanner } = useUserContext();

  const noticeTypeLabel = (anbud: AnbudSummary): string => {
    if (anbud.noticeType === "Notice") return "Kunngjøring av konkurranse";
    else if (anbud.noticeType === "Pin") return "Veiledende";
    else if (anbud.noticeType === "Intention") return "Intensjon";
    else if (anbud.noticeType === "Award") return "Tildeling";
    else return "Ukjent";
  };

  const publicationTypeLabel = (anbud: AnbudSummary): string => {
    if (anbud.publicationType === "European") return "Europeisk";
    else if (anbud.publicationType === "National") return "Nasjonal";
    else return "";
  };

  const formatPrice = (price: number) => {
    if (isNaN(price)) {
      return "-";
    }

    const priceString = price.toString();
    const chunks = [];
    for (let i = priceString.length; i > 0; i -= 3) {
      chunks.unshift(priceString.slice(Math.max(0, i - 3), i));
    }

    return chunks.join(" ") + " kr";
  };

  return (
    <Card elevation={0}>
      <CardContent>
        <Box flexGrow={1}>
          <Grid container alignItems="center">
            <Grid item xs={9}>
              <Typography variant="h6">
                {isAuthenticated ? (
                  <div>
                    <Link href={summary.doffinUrl} target="_blank" rel="noopener" underline="hover">
                      {summary.tittel}
                    </Link>
                  </div>
                ) : (
                  <Box
                    sx={{
                      "&:hover": {
                        color: (theme) => theme.palette.primary.main,
                      },
                      "&:active": {
                        color: (theme) => theme.palette.primary.main,
                      },
                      "&:focus": {
                        color: (theme) => theme.palette.primary.main,
                      },
                    }}
                  >
                    <Link href={`/portal/${paths.signup}?_referral=clickedtitle`} underline="hover">
                      {summary.tittel}
                    </Link>
                  </Box>
                )}
              </Typography>
              <Typography component="div">
                Kunngjøringstype:
                <Box sx={{ fontWeight: "bold", m: 1 }} display="inline">
                  {noticeTypeLabel(summary)}
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid container alignItems="center">
          <Grid item xs={9}>
            <Box sx={{ display: "grid" }}>
              {summary.limitDate && summary.noticeType !== "Award" && (
                <div>Tilbudsfrist: {formatISODate(summary.limitDate)}</div>
              )}
              <div>Publiseringsdato: {formatISODate(summary.dispatchDate)} </div>
              <div>Publikasjonstype: {publicationTypeLabel(summary)} </div>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography component="div">
              Kjøper:
              <Box sx={{ m: 1 }} display="inline">
                {summary.bedriftnavn}
              </Box>
            </Typography>
            <Typography component="div">
              Verdi:
              <Box sx={{ m: 1 }} display="inline">
                {formatPrice(parseInt(summary.priceFixed))}
              </Box>
            </Typography>
            <Typography component="div">
              Referanse:
              <Box sx={{ m: 1 }} display="inline">
                {summary.doffinAnbudId}
              </Box>
            </Typography>
            {summary.noticeType === "Notice" && hasProcurementPlanner && (
                <div>
                    <Link
                        component={RouterLink}
                        to={`/portal/finnunderleverandorer/${summary.doffinAnbudId}`}
                        style={{ textDecoration: 'none' }}
                    >
                        Underleverandører
                    </Link>
                </div>
            )}
          </Grid>
        </Grid>

        <Typography variant="body2" component="p">{summary.beskrivelse}</Typography>
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormLabel component="legend" sx={{ fontSize: '0.75rem', mb: 1 }}>Sted:</FormLabel>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
                fontSize: '0.75rem',
                lineHeight: 1.2,
              }}
            >
              {summary.steder
                .filter(
                  (sted, i, steder) => i === steder.findIndex((s) => s.navn === sted.navn)
                )
                .map((anbudSted) => {
                  const checked = nodeIdOrParentChecked(steder, checkedStedIds, anbudSted.id);
                  const indeterminate = !checked && someChildrenCheckedById(steder, checkedStedIds, anbudSted.id);
                  return (
                    <FormControlLabel
                      sx={{
                        fontSize: '0.75rem',
                        margin: 0,    
                      }}
                      control={
                        <Checkbox
                          checked={checked}
                          indeterminate={indeterminate}
                          onChange={(e, checked) => setCheckedSted(anbudSted.id, checked)}
                          title="Inkluder i søket"
                          color={"primary"}
                          sx={{
                            padding: 0.2,
                            transform: 'scale(0.8)' 
                          }}
                        />
                      }
                      label={anbudSted.navn}
                      key={`sted_${anbudSted.id}`}
                    />
                  );
                })}
            </Box>
          </Grid>

          <Grid item xs={10}>
            <FormLabel component="legend" sx={{ fontSize: '0.75rem', mb: 1 }}>Kategori:</FormLabel>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',       
                gap: 0.5,              
                fontSize: '0.75rem',
                lineHeight: 1.2,
              }}
            >
              {summary.cpvKoder
                .filter((cpvCode, i, cpvKoder) => i === cpvKoder.findIndex((s) => s.beskrivelse === cpvCode.beskrivelse))
                .map((anbudKategori) => {
                  const checked = nodeIdOrParentChecked(kategorier, checkedKategoriIds, anbudKategori.id);
                  const indeterminate =
                    !checked && someChildrenCheckedById(kategorier, checkedKategoriIds, anbudKategori.id);
                  const [numberPart] = anbudKategori.kode.split(" - ");
                  return (
                    <Tooltip title={anbudKategori.beskrivelse} key={`kategori_${anbudKategori.id}`}>
                      <FormControlLabel
                        sx={{
                          fontSize: '0.75rem',
                          margin: 0,
                        }}
                        control={
                          <Checkbox
                            checked={checked}
                            indeterminate={indeterminate}
                            onChange={(e, checked) => setCheckedKategori(anbudKategori.id, checked)}
                            title="Inkluder i søket"
                            color={"primary"}
                            sx={{
                              padding: 0.2,
                              transform: 'scale(0.8)' 
                            }}
                          />
                        }
                        label={numberPart} // Display only the number part
                      />
                    </Tooltip>
                  );
                })}
            </Box>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};
