import React, { FunctionComponent, useEffect, useState } from "react";
import backgroundImage1 from "../../theme/background.jpg";
import { Box, Button, ButtonBase, Checkbox, Grid, Link, List, ListItem, ListItemText, Paper, Radio, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useUserContext } from "../../utils/contexts/UserContext";
import { SignupForm } from "./SignupForm";
import { SignupSideContent } from "./SignupSideContent";
import { useLocation } from "react-router-dom";
import { paths } from "../../app/paths";
import { SearchForm } from "./SearchForm";
import { SignupResult } from "./SignupResult";
import { CheckCircle, Label } from "@mui/icons-material";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { useNavigate } from "react-router-dom";

export const Signup: FunctionComponent = () => {
  const { isLoading } = useUserContext();
  const [orgNumErrorMsg, setOrgNumErrorMsg] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [searchFormTrigger, setSearchFormTrigger] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referralCode = params.get("_referral") ? params.get("_referral")?.toLowerCase() : "";
  const selectedProductNameReferred = params.get("_product") ? params.get("_product")?.toLowerCase() : "PLUSS";
  const [selectedProductName, setSelectedProductName] = useState(selectedProductNameReferred);
  const [externalUserId, setExternalUserId] = useState("");
  const [industryCode, setIndustryCode] = useState("");
  const [selectedProductSmart, setSelectedProductSmart] = useState(false);
  const [selectedProductPluss, setSelectedProductPluss] = useState(true);
  const [selectedProductPremium, setSelectedProductPremium] = useState(false);
  const [isInviteLoading, setIsInviteLoading] = useState(false);
  const navigate = useNavigate();
  
  const [inviteError, setInviteError] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const canSave =
    orgNumErrorMsg === "" 

  useEffect(() => {
    switch (selectedProductNameReferred) {
      case "smart":
        handleProductClick("SMART");
        break;
      case "pluss":
        handleProductClick("PLUSS");
        break;
      case "premium":
        handleProductClick("PREMIUM");
        break;
      default:
        handleProductClick("PLUSS");
        break;
    }

  }, [selectedProductNameReferred]);

  useEffect(() => {
    let invalidReferral = false;
    switch (referralCode) {
      case "mefmember":
      case "mefsorost":
      case "mefvest":
      case "mefmidt":
      case "mefost":
      case "mefsorvest":
      case "clickedtitle":
      case "arkitektbedriftene":
      case "elektroinst":
      case "byggeborsenfauske2024":
      case "byggmesterforbundet":
      case "neso":
      case "tendpro":
        invalidReferral = false;
        break;
      default:
        invalidReferral = true;
        break;
    }

    // Check if referralCode is set and redirect if necessary
    if (referralCode && !invalidReferral) {
      const newSearchParams = new URLSearchParams();
        newSearchParams.append("_referral", referralCode);

      navigate(`${paths.industrysignup}?${newSearchParams.toString()}`);
      console.log(`${paths.industrysignup}?${newSearchParams.toString()}`);
    }

  }, [referralCode]);

  if (isLoading) {
    return null;
  }

  const handleProductClick = (product: string) => {
    switch (product) {
      case 'SMART':
        setSelectedProductSmart(true);
        setSelectedProductPluss(false);
        setSelectedProductPremium(false);
        setSelectedProductName('SMART');
        break;
      case 'PLUSS':
        setSelectedProductSmart(false);
        setSelectedProductPluss(true);
        setSelectedProductPremium(false);
        setSelectedProductName('PLUSS');
        break;
      case 'PREMIUM':
        setSelectedProductSmart(false);
        setSelectedProductPluss(false);
        setSelectedProductPremium(true);
        setSelectedProductName('PREMIUM');
        break;
      default:
        setSelectedProductSmart(false);
        setSelectedProductPluss(true);
        setSelectedProductPremium(false);
    }
  };

  return (
  <>
    {emailSent && !searchFormTrigger && (
      <Grid container spacing={2} style={{ backgroundColor: '#E5F3F3', marginTop: '0.5%' }}>
        <Grid item xs={12} md={12} style={{ }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box textAlign="center" marginTop="10%" marginBottom="10%">
                <Typography variant="h5">Takk for at du registrerte deg hos Finndoff!</Typography>
                <Typography variant="h6">
                  Sjekk innboksen din, og fullfør registreringen fra epost sendt til din epostadresse.
                </Typography>
                <Box marginTop="25px">
                  <Link href={paths.start} sx={{ textDecoration: "none" }}>
                    Tilbake til forsiden
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )}
    {!emailSent && inviteError &&  (
      <Grid container spacing={2} style={{ backgroundColor: '#E5F3F3', marginTop: '0.5%' }}>
        <Grid item xs={12} md={12} style={{ }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box textAlign="center" marginTop="10%" marginBottom="10%">
                <Typography variant="h5">Noe gikk galt med registreringen!</Typography>
                <Link
                  href="https://share-eu1.hsforms.com/183V6W1IGRcikwgsn9G8Nngfai8j"
                  target="_blank"
                  rel="noreferrer"
                  sx={{
                    fontSize: "1.2rem",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontWeight: 500,
                    lineHeight: "1.6",
                    letterSpacing: "0.0075em",
                    position: "relative",
                    color: "#008489",
                    marginBottom: "24px",
                    marginTop: "24px",
                    textAlign: "center",
                  }}
                >
                  Kontakt oss her så hjelper vi deg!
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )}
    {!emailSent && !inviteError &&  (
      <Grid container spacing={2} style={{ backgroundColor: '#E5F3F3',  marginTop: '0.5%' }}>
        <Grid item xs={12} style={{ marginLeft: '10%', marginRight: '10%', marginBottom: '1%', marginTop: '1%', textAlign: 'center' }}>
          <Typography variant="h4">Registrer deg</Typography>
        </Grid>
        <Grid item xs={5} style={{ marginLeft: '8%' }}>
          <Grid container spacing={2} style={{ alignItems: 'flex-start'}} >
              <Grid item xs={12}>
                <Typography variant="h6">Velg Produkt</Typography>
              </Grid>
                <Button
                  onClick={() => handleProductClick('SMART')}
                  sx={{
                    backgroundColor: selectedProductSmart ? '#B2EE51' : 'white',
                    width: '100%',
                    borderRadius: '8px',
                    "&:hover": {
                      backgroundColor: (theme) => '#9DA7AD',
                    },
                    marginBottom:'2%'
                  }}
                >
                <Grid item xs={12}> 
                  <Grid container spacing={2} style={{ paddingBottom: '2%',paddingTop: '2%' }}>
                    <Grid item xs={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
                      <Radio
                        size="medium"
                        checked={selectedProductSmart}
                        onChange={() => handleProductClick('SMART')}
                        value={selectedProductSmart}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'left' }}>
                      <Typography variant="h5" style={{ fontWeight: 'bold' }}>SMART</Typography>
                      <Typography variant="body2" sx={{ display: { xs: 'none', sm: 'block' } }} style={{ marginBottom:'5%' }}>Selvbetjent og kostnadseffektiv</Typography>
                      <Typography variant="h5">
                        <span style={{ fontWeight: 'bold' }}>299,- mnd</span>
                      </Typography>
                      <Typography variant="body2">3.588,- i året</Typography>
                    </Grid>
                    <Grid item xs={5} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                      <List dense>
                        <ListItem style={{ paddingTop: '1px', paddingBottom: '1px' }}>
                          <CustomCheckCircle fontSize="small" /> 
                          <ListItemText primary="1 Epostmottaker" />
                        </ListItem>
                        <ListItem style={{ paddingTop: '1px', paddingBottom: '1px' }}   >
                          <CustomCheckCircle fontSize="small" />
                          <ListItemText primary="1 Varslingsprofil" />
                        </ListItem>
                        <ListItem style={{ paddingTop: '1px', paddingBottom: '1px' }}   >
                          <CustomCheckCircle fontSize="small" />
                          <ListItemText primary="Kredittkortbetaling" />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              </Button>
              <Button
                  onClick={() => handleProductClick('PLUSS')}
                  sx={{
                    backgroundColor: selectedProductPluss ? '#B2EE51' : 'white',
                    width: '100%',
                    borderRadius: '8px',
                    "&:hover": {
                      backgroundColor: (theme) => '#9DA7AD',
                    },
                    marginBottom:'2%'
                  }}
                >
                <Grid item xs={12} style={{ }}> 
                  <Grid container spacing={2} style={{ paddingBottom: '2%',paddingTop: '2%' }}>
                    <Grid item xs={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
                      <Radio
                        size="medium"
                        checked={selectedProductPluss}
                        onChange={() => handleProductClick('PLUSS')}
                        value={selectedProductPluss}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'left' }}>
                      <Typography variant="h5" style={{ fontWeight: 'bold' }}>PLUSS</Typography>
                      <Typography variant="body2" sx={{ display: { xs: 'none', sm: 'block' } }} style={{ marginBottom:'5%' }}>Løsningsoptimalisering med anbudsekspert</Typography>
                      <Typography variant="h5">
                        <span style={{ fontWeight: 'bold' }}>829,- mnd</span>
                      </Typography>
                      <Typography variant="body2">
                        <span>9.948,- i året</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }}}>
                      <List dense>
                        <ListItem style={{ paddingTop: '1px', paddingBottom: '1px' }}>
                          <CustomCheckCircle fontSize="small" /> 
                          <ListItemText primary="3 epostmottakere" />
                        </ListItem>
                        <ListItem style={{ paddingTop: '1px', paddingBottom: '1px' }}   >
                          <CustomCheckCircle fontSize="small" />
                          <ListItemText primary="5 varslingsprofiler" />
                        </ListItem>
                        <ListItem style={{ paddingTop: '1px', paddingBottom: '1px' }}   >
                          <CustomCheckCircle fontSize="small" />
                          <ListItemText primary="Fakturabetaling" />
                        </ListItem>
                        <ListItem style={{ paddingTop: '1px', paddingBottom: '1px' }}   >
                          <CustomCheckCircle fontSize="small" />
                          <ListItemText primary="Løpende løsningsoptimalisering med anbudsekspert" />
                        </ListItem>
                        {/* <ListItem style={{ paddingTop: '1px', paddingBottom: '1px' }}   >
                          <CustomCheckCircle fontSize="small" />
                          <ListItemText primary="Onboarding med ekspert" />
                        </ListItem> */}
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              </Button>
              <Button
                  onClick={() => handleProductClick('PREMIUM')}
                  sx={{
                    backgroundColor: selectedProductPremium ? '#B2EE51' : 'white',
                    width: '100%',
                    borderRadius: '8px',
                    "&:hover": {
                      backgroundColor: (theme) => '#9DA7AD',
                    },
                    marginBottom:'2%'
                  }}
                >
                <Grid item xs={12} style={{ }}> 
                  <Grid container spacing={2} style={{ paddingBottom: '2%',paddingTop: '2%' }}>
                    <Grid item xs={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
                      <Radio
                        size="medium"
                        checked={selectedProductPremium}
                        onChange={() => handleProductClick('PREMIUM')}
                        value={selectedProductPremium}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'left' }}>
                      <Typography variant="h5" style={{ fontWeight: 'bold' }}>PREMIUM</Typography>
                      <Typography variant="body2" sx={{ display: { xs: 'none', sm: 'block' } }} style={{ marginBottom:'5%' }}>Full pakke med budsjett-, innkjøps- og investeringsplaner</Typography>
                      <Typography variant="h5">
                        <span style={{ textDecoration: 'line-through', color: '#757575', marginRight: '5px' }}>1999,-</span> 
                        <span style={{ fontWeight: 'bold' }}>1499,- mnd</span>
                      </Typography>
                      <Typography variant="body2">
                        <span style={{ textDecoration: 'line-through', color: '#757575', marginRight: '5px' }}>23.888,-</span> 
                        <span>17.988,- i året</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                      <List dense>
                        <ListItem style={{ paddingTop: '1px', paddingBottom: '1px' }}>
                          <CustomCheckCircle fontSize="small" /> 
                          <ListItemText primary="10 epostmottakere" />
                        </ListItem>
                        <ListItem style={{ paddingTop: '1px', paddingBottom: '1px' }}   >
                          <CustomCheckCircle fontSize="small" />
                          <ListItemText primary="15 varslingsprofiler" />
                        </ListItem>
                        <ListItem style={{ paddingTop: '1px', paddingBottom: '1px' }}   >
                          <CustomCheckCircle fontSize="small" />
                          <ListItemText primary="Løpende løsningsoptimalisering med anbudsekspert" />
                        </ListItem>
                        <ListItem style={{ paddingTop: '1px', paddingBottom: '1px' }}   >
                          <CustomCheckCircle fontSize="small" />
                          <ListItemText primary="Budsjett-, innkjøps- og investeringsplaner" />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              </Button>
          </Grid>
        </Grid>     
        <Grid item xs={5} style={{  marginLeft: '1%', marginBottom: '0.5%', paddingRight:'1%', marginRight:'2%'}}>
          <Grid item xs={12}>
            {!isSmallScreen && <Typography variant="h6">Bedriftsinformasjon</Typography>}
            {isSmallScreen && <Typography variant="h6">Info</Typography>}
          </Grid>
          {/* Sign up form fields */}
          <Grid item style={{ backgroundColor: 'white', borderRadius: '8px', padding: '2%', flexGrow: 1 }}>
            {/* Render both signup form and loading indicator conditionally */}
            <form style={{ height: '100%' }}>
              <SignupForm emailSent={setEmailSent} inviteError={setInviteError} searchesFormTrigger={setSearchFormTrigger} externalUserId={setExternalUserId} industryCode={setIndustryCode} selectedProduct={selectedProductName} setIsInviteLoading={setIsInviteLoading}/>
            </form>
            {isInviteLoading && (
              <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
                <LoadingIndicator />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    )}
    {emailSent && searchFormTrigger &&  (
      <SearchForm searchesFormTrigger={setSearchFormTrigger} inviteError={setInviteError} externalUserId={externalUserId} industryCode={industryCode}/>
    )}
  </>
  );
};


function CustomCheckCircle(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      {/* Background circle */}
      <circle cx="12" cy="12" r="11" fill="#69DD95" />
      {/* Checkmark */}
      <path
        fill="#0B2333"
        d="M18.293,7.293c0.391,0.391,0.391,1.023,0,1.414l-8,8 c-0.195,0.195-0.451,0.293-0.707,0.293s-0.512-0.098-0.707-0.293l-4-4c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0 l3.293,3.293L17.879,7.879C18.27,7.488,17.902,6.902,18.293,7.293z"
      />
    </SvgIcon>
  );
}

export default CustomCheckCircle;
